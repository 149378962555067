import React from 'react'



function OurVarious() {


    return (
      <section className="icon whitelabel-innove pt-100 secureof">
        <div className="container">
          <h3 className="heading-h2 text-center"><span className='bluecolor'>Extended</span> Capabilities of our White Label Crypto Wallets</h3>
          <p className='text-center'>Explore the enhanced potential of our white-label crypto wallets, designed to go beyond basic.  With these advanced integrations and innovative features, we ensure an efficient user experience tailored to your business goals.</p>
          <div className="row">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                API Integration
                  </h3>
                <p className="pharagraph">Our White-label digital wallet makes it possible to integrate APIs without any hassle. We allow you to connect your backend infrastructure, enabling smooth communication and data exchange between your platform and our crypto wallet. This empowers you to unlock the full potential of our wallet's features.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Exchange Integration
                </p>
                <p className="pharagraph">You can easily integrate your crypto exchange with our White-label Wallet, providing your users with a straightforward way to make crypto trading. With, a single interface you can manage assets, keep an eye on market prices, and make transactions. This removes the need for multiple logins or platforms.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Payment Gateway Integration
                </p>
                <p className="pharagraph">Seamlessly integrate your payment gateway with our ready-made white-label crypto wallet solution and enable effortless, secure transactions directly from the wallet interface. With a smooth payment flow, users can send and receive cryptos with just a few taps, increasing customer comfort.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Decentralized Application (DApps) Integration
                </p>
                <p className="pharagraph">Integrate your dApps with our Crypto Wallet to fuel the power of blockchain technology. This opens up fresh possibilities for your dApps, fostering broader acceptance globally. Besides, this integration elevates the overall end-user experience and automatically enhances the functionality of all your transactions.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Third-party service Integration
                </p>
                <p className="pharagraph">Enhance the user experience by incorporating trusted third-party services like KYC/AML verification, identity authentication, and analytics tools. These integrations strengthen security and provide valuable insights into user behavior and transaction patterns, creating a feature-rich wallet experience.</p>
              </div>
              <div className='leftdiv'>
                <p className='head3'>
                Blockchain Network Integration
                </p>
                <p className="pharagraph">With our White-label wallet, you can incorporate an enormous variety of blockchain networks and communicate with them effortlessly. You can offer a diverse range of digital assets, and applications, backed by blockchain protocols. This increases the user's flexibility, driving your business growth.</p>
              </div>
              </div>
          </div>
          
        </div>
      </section>
    )
  
}

export default OurVarious