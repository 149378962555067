import React from 'react'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
              <h4 className="heading-h2 text-center">Why is Coinsclone the <span className="bluecolor">Smartest Choice</span> for White Label Crypto Wallet Development?</h4>

              <p className="pharagraph">Coinsclone is your go-to partner for the best white label crypto solution needs, backed by our extensive experience and expertise. Our team of skilled developers will entrust you with a secure, customizable, and trendy digital wallet infusing the latest technology.
              </p>
              <div className='boxtype'>
                <p>End-to-End Support</p>
                <p>On-Time Project Delivery</p>
                <p>24/7 Uninterrupted Service</p>
                <p>Cost-Effective Packages</p>
                <p>Quick & Customizable Setup</p>
                <p>Post-Launch Support</p>
              </div>
              </div>
            </div>
      </section>
    )
  }
}

export default WhyChoose