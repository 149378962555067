import React,{useState, useEffect} from 'react'
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/whitelable-wallet/Banner';
import Whatis from '../components/whitelable-wallet/Whatis';
import OurVarious from '../components/whitelable-wallet/OurVarious';
import CoreFeatures from '../components/whitelable-wallet/CoreFeatures';
import Popular from '../components/whitelable-wallet/Popular';
import DevProcess from '../components/whitelable-wallet/DevProcess';
import WhyChoose from '../components/whitelable-wallet/WhyChoose';
import TechStack from "../components/whitelable-wallet/TechStack";
import FaqSection from '../components/whitelable-wallet/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import Benificial from '../components/whitelable-wallet/Benificial';
import DevApproach from '../components/whitelable-wallet/DevApproach';
import PortfolioSection from '../components/Landing/Portfolio';
import WalletApp from '../components/whitelable-wallet/WalletApp';  



  
const WhitelabelWallet = () => {


  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return(

  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/white-label-crypto-wallet/" />
        <title>White Label Crypto Wallet</title>
        <meta name="description" content="Coinsclone provides the Best White Label Crypto Wallet, offering a pre-built, fully tested, and customizable solution for securely storing, sending, and receiving coins, tokens, NFTs, stablecoins, and other digital assets." />
        <meta name="keywords" content="White label Crypto Wallet" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="White Label Crypto Wallet" />
        <meta property="og:description" content="Coinsclone provides the Best White Label Crypto Wallet, offering a pre-built, fully tested, and customizable solution for securely storing, sending, and receiving coins, tokens, NFTs, stablecoins, and other digital assets." />
        <meta property="og:url" content="https://www.coinsclone.com/white-label-crypto-wallet/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/whitelabel-wallet/white-label-crypto-wallet.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone provides the Best White Label Crypto Wallet, offering a pre-built, fully tested, and customizable solution for securely storing, sending, and receiving coins, tokens, NFTs, stablecoins, and other digital assets." />
        <meta name="twitter:title" content="White Label Crypto Wallet" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/whitelabel-wallet/white-label-crypto-wallet.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | White Label Crypto Wallet </span>
        </div>
      </div>
      <Whatis />
      <WalletApp />
      <CoreFeatures />
      <DevProcess />
      <Benificial />
      <OurVarious />
      <Popular />
      <DevApproach />
      {/* <UseCase /> */}
      <WhyChoose />
      <div className='dot-box'>
        <p>Want a seamless way to launch your crypto wallet? Try our secure and cost-effective White Label crypto wallet solution!</p>
        <div className='dot-btn'>
        <a href="https://www.coinsclone.com/contact-us/" className="bluebtn">View live Demo</a>
        </div>
      </div>
      {isDesktop && (
      <PortfolioSection />
      )}
      <TechStack />
      
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
  )
  }

export default WhitelabelWallet
