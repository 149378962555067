import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h6 className="heading-h2 text-center">Frequently Asked Questions?</h6>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is a White label Crypto Wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">White label Crypto Wallet solution is a ready-made software that enables you to launch your own crypto wallet with minimal customizations. This allows users to easily buy, sell, and swap the cryptos. Furthermore, you can tailor the solution according to your business goals.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                How Long Does it Take to Deploy a White Label Crypto Wallet?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">On an average note, the time taken to deploy a white label crypto wallet can take from 15 days to 30 days and more. This duration is fixed based on various factors like business requirements, customization, add-ons, and several other elements.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How Much Will It Cost to Develop a White-label Crypto Wallet?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The approximate cost to launch White-label Wallet solutions cannot be determined roughly. The cost is determined based on various factors such as customization, project complexity, and other influential factors.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                What are the Key Advantages of Developing a White-Label Crypto Wallet?  
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The benefits of adopting a white label crypto wallet are high RoI, faster deployment, cost efficiency, and customization. This is an easy way to create branding for your crypto wallet business.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How can a white label crypto wallet generate revenue for my business?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">A white-label cryptocurrency wallet can generate revenue via transaction fees, subscription models, and premium features such as staking, trading, and asset management services.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection