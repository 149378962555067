import React from 'react'


const Popular = () => {


  return (
    <section className="pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="bluecolor">Built-in</span> Functionalities of Our White Label Crypto Wallet Solution
            </h3>
            <p className="pharagraph head">Explore our white-label crypto Wallet to find a broad range of possibilities. These customizable solutions with automated add-on functionalities will ensure smooth operations for your business.
            </p>
          </div>
        </div>
        <div className='row popular we-offer'>
            <div className='col-md-12 col-lg-3'>
                <p className="head3">Limitless Cryptocurrencies</p>
                <p className='pharagraph'>Our White-Label Wallet utilizes an advanced technology stack for the seamless management of multiple cryptocurrencies. This also offers intuitive staking options for earning rewards and improving the liquidity.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <p className="head3">Handle NFTs</p>
                <p className='pharagraph'>Coinsclone’s White label Crypto Wallet is NFT-ready, allowing users to accumulate and securely store NFTs. Multi-chain interoperability is supported by our White-label digital wallet solution, increasing its accessibility.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <p className="head3">Transactions</p>
                <p className='pharagraph'>The wallet has diverse transaction options that allow users to transfer both NFTs and cryptos in versatile ways. Additionally, the users can use the wallet's instant payment feature to settle their bills with cryptocurrency.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <p className="head3">Fiat Currency</p>
                <p className='pharagraph'>We introduce the ability to use fiat money in the wallet seamlessly, allowing users to directly buy and sell cryptos easily. For users, this fiat-friendly approach makes the wallets their go-to choice for all payment needs.</p>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Popular