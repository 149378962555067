import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>White Label</span> Crypto Wallet</h1>
                <p className="pharagraph">Build your own Crypto Wallet using our ready-made, customizable white label crypto wallet solutions. Kickstart your journey with our personalized, secure, and feature-packed wallet, which can be branded according to your business requirements.</p>
                <ul>
                  <li className='pharagraph'>Customizable and Complete Brand Ownership</li>
                  <li className='pharagraph'>Fastest Deployment & Market entry</li>
                  <li className='pharagraph'>Top Tier Security Protocols</li>
                </ul>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 white_wltbanner">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel-wallet/white_label_crypto_wallet.webp"
                alt="White Label Crypto Wallet banner"
                className='d-none d-md-block'
                width={462}
              /> 
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection