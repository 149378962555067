import React from "react"

class ProcessOf extends React.Component {

  render() {
    return (

      <section className="process-of white-wallet pt-100 mb-0">
          <div className="container"> 
            <h3 className="heading-h2 text-center">
            <span className="bluecolor">Level Up</span> Your White Label Crypto Wallet App With Our Add-On Features
            </h3>
            <p className="text-center">
            With our powerful add-on features, you can enhance the security, user experience, and performance of wallet to the next level. Explore some of your top picks that can set your wallet apart.
            </p>
            <div className="d-lg-flex add-one">
              <div className="square" >
                <p className="head3">Brand Building</p>
                <p className="pharagraph">
                By white labeling a crypto wallet, you can build your own strong brand identity. By personifying your logos, and interfaces you can create a wallet that speaks your brand’s language.
                </p>
              </div>
              <div className="square">
                <p className="head3">Cryptographic Timestamping</p>
                <p className="pharagraph">
                This adds a layer of verifiable proof of transaction history, promising accuracy and transparency. This helps maintain tamper-proof records, making it ideal for audits, and tax reporting.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Biometric Authentication</p>
                <p className="pharagraph">
                This enhances security by using facial recognition or fingerprint scanning for access. This provides an extra layer of protection, ensuring only authenticated access into the crypto wallet.
                </p>
              </div>
            </div>
            <div className="d-lg-flex add-two">
              <div className="square" >
                <p className="head3">Feature Addition</p>
                <p className="pharagraph">
                Our white label crypto wallet is renowned for its flexibility, allowing seamless integration of new functionalities as your platform needs evolve. With this feature your wallet can stay up to date, aligning with the latest trends and innovations.
                </p>
              </div>
              <div className="square" >
                <p className="head3">Staking Features</p>
                <p className="pharagraph">
                Integrating staking functionality lets users earn passive income by locking their assets, all while keeping full control over their digital assets. The crypto wallet simplifies staking with an easy-to-use interface and real-time reward tracking.
                </p>
              </div>
            </div>
            <div className="d-lg-flex add-three">
              <div className="square" >
                <p className="head3">Language & Localization</p>
                <p className="pharagraph">
                Our crypto wallet supports multiple languages ensuring a seamless user experience for global users. This enhances user accessibility, allowing users to explore in their preferred language.
                </p>
              </div>
              <div className="square">
                <p className="head3">Notifications & Alerts</p>
                <p className="pharagraph">
                With this, you get instant alerts for transactions, prices, and security movements. These customizable notifications, ensure you stay informed about important wallet activities. 
                </p>
              </div>
              <div className="square" >
                <p className="head3">Chrome Wallet Extension</p>
                <p className="pharagraph">
                A Chrome Wallet Extension brings your crypto transaction to your browser for instant access. You can send, receive, and manage digital assets seamlessly without switching between tabs.
                </p>
              </div>
            </div>
            <div className="mt-3 text-center">
                <a href="/contact-us/" className="bluebtn">Consult our Expert Team</a>
            </div>
          </div>
      </section>

    )
  }
}

export default ProcessOf
