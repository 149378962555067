import React, { Component } from 'react'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="usr-admin-tab pt-100 mb-0">
        <div className="gray-bg">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Exemplary Features</span> of Our White Label Crypto Wallet</h2>
            <p className='text-center'>Our white-label crypto wallet comes packed with essential and versatile features. From basic tools to advanced capabilities, every feature is designed carefully to enhance your crypto wallet business.</p>
          <div className="row">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  <h3>Basic Features</h3>
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  <h3>Advanced Features</h3>
                </li>
              </ul>

              <div className="tab-content px-0">
                <div id="tab1" className="tab-pane tabs_items active">
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='fea-card'>
                            <p className='head3'>Address Book</p>
                            <p className='pharagraph'>This feature enables users to store frequently used wallet addresses for quick, error-free transactions, eliminating the hassles of mismatched wallet addresses.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Multiple Wallets</p>
                            <p className='pharagraph'>The users can create, store, and manage multiple wallets within the single wallet interface. This minimizes the hassle of switching platforms, allowing efficient transfers.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Ledger Support</p>
                            <p className='pharagraph'>Ledger support ensures that the private keys remain offline, ensuring the highest level of security. This reduces the risks of unauthorized access, increasing trust.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Native Fiat Currency</p>
                            <p className='pharagraph'>Integrating native currency support enables easy and smooth crypt-fiat conversions.  This strengthens user adoption while making them more accessible to everyday transactions.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Asset Management</p>
                            <p className='pharagraph'>Users can effortlessly monitor, transfer, and track their digital assets and portfolios in real-time. This makes asset handling more convenient for both individuals and businesses.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Export/Import Private Keys</p>
                            <p className='pharagraph'>This feature securely exports and imports private keys, ensuring easy wallet recovery. This feature ensures seamless tokens while maintaining a strong security protocol.</p>
                        </div>
                    </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='fea-card'>
                            <p className='head3'>Airdrops</p>
                            <p className='pharagraph'>You can distribute crypto tokens to multiple users quickly and effectively. The crypto tokens are boasted with adoption and gain good traction within the community.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Prepaid Cards</p>
                            <p className='pharagraph'>The users can access the prepaid debit cards for making payments. They can also top up the card with crypto and obtain a fiat equivalent balance, enhancing usability.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>User Chats</p>
                            <p className='pharagraph'>This is like the support tool, that allows users to connect and communicate effortlessly. With this feature, the platform users can discuss token transactions or updates instantly.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Cross & On-chain Crypto Swaps</p>
                            <p className='pharagraph'>Cross-chain and on-chain swap features enable seamless token exchanges across different blockchain networks, increasing the token’s liquidity and flexibility.</p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>QR Code Payments</p>
                            <p className='pharagraph'>
                            Having an advanced feature of QR codes makes transactions faster and more user-friendly. This feature streamlines token transfers, enhancing the payment experience.
                            </p>
                        </div>
                        <div className='fea-card'>
                            <p className='head3'>Real time Tracking tools</p>
                            <p className='pharagraph'>Our real-time tracking tools give users live facts, past data, pricing charts, and other analytical elements to execute plans and make informed strategic decisions.</p>
                        </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures