import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
              <h2 className="heading-h2 text-center"><span className='heading-h3 bluecolor'>What is</span> White Label Crypto Wallet?</h2>
            <div className="text-center">
              <p className="pharagraph text-center">White Label Crypto Wallet is a pre-built, customizable digital wallet solution that allows businesses to launch their own crypto wallets quickly with essential features. These wallets are designed for securely storing and managing various cryptocurrencies, NFTs, stablecoins, and other digital assets. Our wallets are extensively tested and are ready for immediate deployment, while integrating with blockchain projects, crypto exchanges, and investment platforms, seamlessly. Furthermore, they are equipped with advanced security features to safeguard against potential threats and attacks.
              </p>
              <p className="pharagraph text-center mb-0">As this is ready-made software, this can save up your development time and costs, while offering high-quality wallet services. With over a decade of experience, Coinsclone is known for delivering the best white label cryptocurrency wallet solutions featuring advanced functionalities crafted to your business needs. We offer extensive customization options to make your wallet solution precisely aligned with your vision.
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis